<template>
  <div class="cmp">
    <div v-for="(item, key) in games" class="games" @click="select_game(key)">
      <p>{{key}} {{item.game_id}}</p>
      <img :src="item.img">
    </div>
  </div>
</template>

<script setup>
import {inject, ref} from "vue";

const props = defineProps({
  data: Object
})

const router=inject('router')
const route=inject('route')

const games=ref({
  race: {game_id: 66, img: './resources/race/img/bg.jpg', name: 'Race'},
  keno: {game_id: 50, img: './resources/keno/img/bg.png', name: 'Keno'},
  kenoext: {game_id: 67, img: './resources/kenoext/img/bg.png', name: 'Kenoext'},
})

const setting=inject('setting')

function select_game(key)
{
  router.push({path: key});
}
</script>

<style scoped>
.cmp {
  position: relative;
}

.games {
  float: left;
  text-align: center;
  cursor: pointer;
  margin: 20px;
  padding: 25px;
  border: black 1px solid;
  border-radius: 10px;
  background: #919797;
  box-shadow: 0 0 50px;
}

.games img {
  width: 300px;
}
</style>