import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
// lazy-loaded
const Home = () => import("@/components/Home.vue")

let routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  }
];

let routes_games = {
  keno: {code: 'keno', component_path: 'Keno', component: 'Keno', path: 'keno', name: 'Keno'},
  kenoext: {code: 'kenoext', component_path: 'Kenoext', component: 'Kenoext', path: 'kenoext', name: 'Kenoext'},
  cock: {code: 'cock', component_path: 'Cock', component: 'Main', path: 'cock', name: 'Cock'},
  moto: {code: 'moto', component_path: 'Moto', component: 'Main', path: 'moto', name: 'Moto'},
  horse: {code: 'horse', component_path: 'Horse', component: 'Main', path: 'horse', name: 'Horse'},
  dog: {code: 'dog', component_path: 'Dog', component: 'Main', path: 'dog', name: 'Dog'},
  ruleta1: {code: 'ruleta1', component_path: 'Roulette1', component: 'Roulette1', path: 'ruleta1', name: 'Ruleta1'},
  footbolitos: {code: 'footbolitos', component_path: 'Footbolitos', component: 'Footbolitos', path: 'footbolitos', name: 'Footbolitos'},
}

var CompGames = {}

for(let key in routes_games) {
  let cur = routes_games[key]

  if(process.env.VUE_APP_GAME==cur.code || process.env.VUE_APP_GAME=='') {
    CompGames[cur.code] = () => import("@/components/"+cur.component_path+"/"+cur.component+".vue")

    routes.push({
      path: "/"+cur.path,
      name: cur.name,
      // lazy-loaded
      component: CompGames[cur.code]
    })
  }
}

const router = createRouter({
  history: createWebHistory('#'),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    sessionStorage.setItem('user', '{}')
    next('/login')
  } else {
     next();
  }
});

router.afterEach((to, from) => {
  let title=to.name
  document.title = title;
  /*Vue.nextTick(() => {
    document.title = title;
  });*/
});

export default router;